import React, {useState} from 'react';
import {FaArrowCircleUp} from 'react-icons/fa';
import './layout.css';

// Check if window is defined (so if in the browser or in node.js).
const isBrowser = typeof window !== "undefined"

const ScrollArrow = () =>{

  const [showScroll, setShowScroll] = useState(false)

  const checkScrollTop = () => {
    if (!showScroll && window.pageYOffset > 400){
      setShowScroll(true)
    } else if (showScroll && window.pageYOffset <= 400){
      setShowScroll(false)
    }
  };

  const scrollTop = () =>{
    window.scrollTo({top: 0, behavior: 'smooth'});
  };

  if (isBrowser) {
    window.addEventListener('scroll', checkScrollTop)
  };

  return (
        <FaArrowCircleUp className="scrollTop" onClick={scrollTop} style={{height: 40, display: showScroll ? 'flex' : 'none'}}/>
  );
}

export default ScrollArrow;