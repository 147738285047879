import React, { useState } from "react";
import {Link} from "gatsby";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink
} from 'reactstrap';

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return(
    <div>
      <Navbar  light expand="md" className="bg-white">
        <div className="container py-lg-4 pr-md-0">
          <NavbarBrand><Link className="logo" to="/">G1Core</Link></NavbarBrand>
          <NavbarToggler onClick={toggle} />
          <Collapse isOpen={isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <Link className="navItem" to="/"><NavLink className="navLink">Home</NavLink></Link>
              </NavItem>
              <NavItem>
                <NavLink href="/#principles" className="navLink">Principles</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/#games" className="navLink">Games</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/#team" className="navLink">Team</NavLink>
              </NavItem>
              {/* <NavItem>
                <NavLink href="/#blog" className="navLink">Blog</NavLink>
              </NavItem> */}
              <NavItem>
                <NavLink href="/#contact-us" className="btn btn-primary text-white btn-contact ml-lg-4 mt-3 mt-sm-0">Contact Us</NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </div>
      </Navbar>
    </div>
  )
}

export default Header
