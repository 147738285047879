import * as React from "react";
import {Link} from "gatsby";
import { Row, Col, List } from 'reactstrap';

const Footer = () => {
  return(
    <footer className="clearfix">
      <div className="container">
      <Row>
      <Col lg="7" className="pb-4 pb-lg-0">
        <div className="mb-4">
          <Link className="logo" to="/">G1Core</Link>
        </div>
        <p className="copyright">&#169; 2021 G1Core. All rights reserved.</p>
      </Col>
      <Col lg="5">
        <Row>
          <Col sm="5" className="py-4 py-lg-0">
            <h5>Navigation</h5>
            <List type="unstyled">
              <li><Link to="/" className="footer-link">Home</Link></li>
              <li><Link to="/#principles" className="footer-link">Principles</Link></li>
              <li><Link to="/#games" className="footer-link">Games</Link></li>
              <li><Link to="/#team" className="footer-link">Team</Link></li>
              {/* <li><Link to="/#blog" className="footer-link">Blog</Link></li> */}
            </List>
          </Col>
          <Col sm="5" className="py-4 py-lg-0">
            <h5>Contact Us</h5>
            <List type="unstyled">
              <li><a href="mailto:contact@g1core.com" className="footer-link">contact@g1core.com</a></li>
            </List>
          </Col>
        </Row>
      </Col>
      </Row>
      </div>
    </footer>
  )
}

export default Footer